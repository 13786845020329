div.Home {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 100vh;
  color: white;
  overflow: hidden;
}

div.Home::before {
  content: "";
  position: absolute;
  background-image: url("../images/home.jpg");
  background-size: cover;
  width: 110%;
  height: 110%;
  top: -10%;
  z-index: -1;
  transition: all 1s;
}

div.Home:hover::before {
  width: 120%;
  height: 120%;
}

div.Home .title,
div.Home .subtitle,
div.Home .description,
div.Home a {
  width: 100vw;
  text-align: center;
}

div.Home .title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 3rem;
  background-color: black;
}

div.Home .subtitle {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 2.5rem;
  background-color: black;
}

div.Home .description {
  padding-top: 1rem;
  background-color: var(--TransparentBlack);
  font-size: 1.5rem;
  font-style: italic;
}

div.Home a {
  background-color: var(--TransparentBlack);
  padding: 1rem 0rem;
  font-size: 1.5rem;
}

div.Home a:visited {
  color: inherit;
}

div.Home a:hover {
  color: orange;
}
