div.OrderTotal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(100vh - var(--OrderTotalHeight));
  background-color: var(--TransparentBlack);
  height: var(--OrderTotalHeight);
  width: 100%;
  text-align: end;
  font-size: 2rem;
  color: white;
}

div.OrderTotal button {
  height: 100%;
  background-color: bisque;
  font-size: 2rem;
}
